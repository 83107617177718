body {
    /*background: #F9F9F9 !important;*/
}

.navbar.is-info {
    background-color: initial;
    box-shadow: none !important;
}

h1 {
    font-family: 'Sen', sans-serif;
    font-size: 28px;
    font-weight: 700;
    color: #000;
    margin-bottom: 10px;
    padding: 0 37px;
}

h2 {
     font-family: Roboto, sans-serif;
     font-size: 16px;
     font-weight: 500;
     line-height: 21px;
     color: #191919;
     margin-bottom: 24px;
 }

p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #5D5D5D;
    padding-right: 100px;
}

#wheelContainer {
    position: relative;
}

#description {
    padding: 0 37px;
    font-family: Sen, sans-serif;
    font-size: 16px;
}

.text-box {
    margin-top: 48px;
}

.navbar-menu.is-active {
    position: absolute;
}

.instructionsText > div {
    animation: rotation 10s infinite linear;
    font-size: 4.7vw;
}

@media screen and (min-width: 769px) {
    .instructionsText > div {
        font-size: 2.35vw;
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

@media screen and (min-width: 1024px) {
    .navbar.is-info .navbar-start > .navbar-item,
    .navbar.is-info .navbar-start .navbar-link,
    .navbar.is-info .navbar-end > .navbar-item,
    .navbar.is-info .navbar-end .navbar-link {
        color: initial;
    }
}